import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'n7-modal-form',
  templateUrl: './n7-modal-form.component.html',
  styleUrls: ['./n7-modal-form.component.scss'],
})
export class N7ModalFormComponent implements OnInit {
  public titleLabel: string;
  public formItems: any[] = [];
  public type: string = 'modal';
  public dismissLabel: string;
  public buttonClicked: number = null;

  constructor(
    private modalController: ModalController,
    private popoverController: PopoverController,
    private navParams: NavParams
  ) {
    this.titleLabel = this.navParams.get('titleLabel');
    this.formItems = this.navParams.get('formItems');
    if (this.navParams.get('type')) {
      this.type = this.navParams.get('type');
    }
    this.dismissLabel = this.navParams.get('dismissLabel');
  }

  ngOnInit() { }

  dismiss() {
    // console.log('this.formItems', this.formItems);
    switch (this.type) {
      case 'modal':
        this.modalController.dismiss(
          {
            confirmed: true,
            formItems: this.formItems,
            buttonClicked: this.buttonClicked,
          }
        );
        break;

      case 'popover':
        this.popoverController.dismiss(
          {
            confirmed: true,
            formItems: this.formItems,
            buttonClicked: this.buttonClicked,
          }
        );
        break;

    }
  }

  addItem(index, emptyItem) {
    let newItem = Object.assign({}, emptyItem);
    this.formItems[index].value.push(newItem);
  }

  buttonClick(index) {
    // console.log('buttonClick', index);
    this.buttonClicked = index;
    this.dismiss();
  }
}
