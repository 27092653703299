import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { appReducer, metaReducers } from './app.reducer';
import { UserEffects } from './user/user.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(
      appReducer,
      { metaReducers }
    ),
    EffectsModule.forRoot([UserEffects])
  ],
  declarations: []
})

export class StateModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: StateModule
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: StateModule) {
    // console.log('StateModule');
    if (parentModule) {
      throw new Error(
        'StateModule is already loaded. Import it in the AppModule only');
    }
  }
}