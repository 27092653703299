import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { ResourceService } from 'src/app/core/services/resource.service';
import { Utilities } from 'src/common/utilities.class';

@Component({
  selector: 'app-st-modal-import-resources',
  templateUrl: './st-modal-import-resources.component.html',
  styleUrls: ['./st-modal-import-resources.component.scss'],
})
export class StModalImportResourcesComponent implements OnInit {
  fileUploadValue: any;
  isImport: boolean;
  isLoading: boolean;
  isCompleted: boolean;
  isError: boolean;
  successes: any[];
  errorLog: string[];
  hasErrorLog: boolean;

  constructor(
    public modalController: ModalController,
    private resourceService: ResourceService
  ) {
    this.isImport = false;
    this.isLoading = false;
    this.isCompleted = false;
    this.isError = false;
    this.hasErrorLog = false;
  }

  ngOnInit() {
    this.isImport = true;
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  onFileChange(event) {
    // console.log(event);
    this.fileUploadValue = event;
    // this.fileChange.emit(this.fileUploadValue);
  }

  async doImport() {
    this.isImport = false;
    this.isLoading = true;
    this.hasErrorLog = false;

    try {
      this.successes = [];
      this.errorLog = [];
      const response: any = await this.resourceService.createFromCSV(this.fileUploadValue);

      // console.log('response', response);

      response.data.successes.forEach(success => {
        this.successes.push({
          'type': 'resources',
          'id': success.id
        });
      });


      this.isLoading = false;
      this.isCompleted = true;
    } catch (error) {
      // console.log('error', error);
      this.errorLog = error.error.error;
      this.hasErrorLog = (this.errorLog && this.errorLog.length && (this.errorLog.length > 0));
      this.isLoading = false;
      this.isError = true;
    }
  }

  downloadErrorLog() {
    // console.log('errorLog', this.errorLog);
    let txtFile = '';
    if (this.hasErrorLog) {
      this.errorLog.forEach(row => {
        txtFile += row + '\n';
      });
      let fileMIMEType = 'text;charset=utf-8';
      var blob = new Blob([txtFile], { type: fileMIMEType });
      saveAs(blob, 'error-log.txt');
    }
  }

  async downloadBadges() {
    // console.log('successes', this.successes);
    const data = {
      'data': this.successes
    };
    const badges: any = await this.resourceService.downloadBadges(data);

    let fileMIMEType = 'application/zip';
    // let fileMIMEType = 'application/pdf';
    var blob = new Blob([badges.body], { type: fileMIMEType });
    saveAs(blob, 'badges.zip');
  }

  async downloadTemplate() {
    const DocumentUrl = 'https://docs.google.com/spreadsheets/d/1aCMg29ptadqICVm7kAJleomcEoU0FGfNeeCd54uTlNU/edit#gid=0';
    window.open(DocumentUrl, '_blank');
    // const csvFile: string = await this.resourceService.downloadSampleCSV();
    // let fileMIMEType = 'text/csv;charset=utf-8';
    // var blob = new Blob([csvFile], { type: fileMIMEType });
    // saveAs(blob, 'sample.csv');
  }
}
