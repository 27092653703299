import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginGuard } from './core/guards/login.guard';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'auth', loadChildren: './pages/login/login.module#LoginPageModule', canActivate: [LoginGuard] },
  { path: 'reset-password', loadChildren: './pages/reset-password/reset-password.module#ResetPasswordPageModule' },
  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' },
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule', canActivate: [AuthGuard] },

  { path: 'shipyard/:shipyard', loadChildren: './pages/shipyard/shipyard.module#ShipyardPageModule', canActivate: [AuthGuard] },
  { path: 'shipyard/:shipyard/edit', loadChildren: './pages/shipyard-edit/shipyard-edit.module#ShipyardEditPageModule', canActivate: [AuthGuard] },

  { path: 'add-new-yacht', loadChildren: './pages/add-new-yacht/add-new-yacht.module#AddNewYachtPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'add-new-project/:boat_id/:project_type', loadChildren: './pages/add-new-project/add-new-project.module#AddNewProjectPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'add-yacht-history/:boat_id', loadChildren: './pages/add-yacht-history/add-yacht-history.module#AddYachtHistoryPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'project-sections/:project_id/:boat_id', loadChildren: './pages/project-sections/project-sections.module#ProjectSectionsPageModule' },
  { path: 'boat/:boat_id/project/:project_id/edit/sections', loadChildren: './pages/project-sections/project-sections.module#ProjectSectionsPageModule' },
  { path: 'project-users/:project_id/:boat_id', loadChildren: './pages/project-users/project-users.module#ProjectUsersPageModule' },
  { path: 'project-zones/:project_id/:boat_id', loadChildren: './pages/project-zones/project-zones.module#ProjectZonesPageModule' },
  { path: 'project-products/:project_id/:boat_id', loadChildren: './pages/project-products/project-products.module#ProjectProductsPageModule' },

  { path: 'boat/:boat_id/project/:project_id/edit/drive-folder', loadChildren: './pages/project-drive-folder/project-drive-folder.module#ProjectDriveFolderPageModule', canActivate: [AuthGuard] },
  { path: 'boat/:boat_id/project/:project_id/edit/budget', loadChildren: './pages/project-budget/project-budget.module#ProjectBudgetPageModule', canActivate: [AuthGuard] },

  { path: 'edit-yacht/:boat_id', loadChildren: './pages/edit-yacht/edit-yacht.module#EditYachtPageModule', canActivate: [AuthGuard] },
  // { path: 'edit-project/:project_id', loadChildren: './pages/edit-project/edit-project.module#EditProjectPageModule' },

  // { path: 'boat/:boat_id', loadChildren: './pages/boat/boat.module#BoatPageModule' },
  { path: 'boat/:boat_id/project/:project_id', loadChildren: './pages/boat/boat.module#BoatPageModule', canActivate: [AuthGuard] },

  // parte di aggiunta del punto
  // tslint:disable-next-line: max-line-length
  { path: 'boat/:boat_id/project/:project_id/task/:task_id', loadChildren: './pages/task/task.module#TaskPageModule', canActivate: [AuthGuard] },

  // tslint:disable-next-line: max-line-length
  { path: 'add-draft-map/:section_id/:project_id/:boat_id', loadChildren: './pages/add-draft-map/add-draft-map.module#AddDraftMapPageModule' }, // 1
  { path: 'add-draft-photo/:task_id', loadChildren: './pages/add-draft-photo/add-draft-photo.module#AddDraftPhotoPageModule' },// 2
  { path: 'add-draft-draw/:task_id', loadChildren: './pages/add-draft-draw/add-draft-draw.module#AddDraftDrawPageModule' },// 3
  { path: 'add-draft-info/:task_id', loadChildren: './pages/add-draft-info/add-draft-info.module#AddDraftInfoPageModule' },// 4
  // all section page
  {
    path: 'boat/:boat_id/project/:project_id/sections',
    loadChildren: './pages/all-sections/all-sections.module#AllSectionsPageModule'
  },

  // Exports
  { path: 'boat/:boat_id/project/:project_id/export/list-of-points', loadChildren: './pages/export-list-of-points/export-list-of-points.module#ExportListOfPointsPageModule' },
  { path: 'boat/:boat_id/project/:project_id/export/environmentals', loadChildren: './pages/export-environmentals/export-environmentals.module#ExportEnvironmentalsPageModule' },
  { path: 'boat/:boat_id/project/:project_id/export/application', loadChildren: './pages/export-application/export-application.module#ExportApplicationPageModule' },
  { path: 'boat/:boat_id/project/:project_id/export/available-surface', loadChildren: './pages/export-available-surface/export-available-surface.module#ExportAvailableSurfacePageModule' },

  // Logs
  { path: 'boat/:boat_id/project/:project_id/log/application', loadChildren: './pages/application-log/application-log.module#ApplicationLogPageModule' },
  { path: 'boat/:boat_id/project/:project_id/log/environmental', loadChildren: './pages/environmental-log/environmental-log.module#EnvironmentalLogPageModule' },
  { path: 'boat/:boat_id/project/:project_id/surface/availability', loadChildren: './pages/surface-availability/surface-availability.module#SurfaceAvailabilityPageModule' },

  // Remark flow
  {
    path: 'boat/:boat_id/project/:project_id/log/remark/:document_id',
    loadChildren: './pages/remark-log/remark-log.module#RemarkLogPageModule'
  },

  { path: 'statics/theme', loadChildren: './statics/theme/theme.module#ThemePageModule' },
  { path: 'statics/icons', loadChildren: './statics/icons/icons.module#IconsPageModule' },
  { path: 'statics/home', loadChildren: './statics/home/home.module#HomeStaticPageModule' },
  { path: 'statics/shipyard', loadChildren: './statics/shipyard/shipyard.module#ShipyardStaticPageModule' },
  { path: 'statics/resources', loadChildren: './statics/resources/resources.module#ResourcesStaticPageModule' },
  { path: 'statics/management', loadChildren: './statics/management/management.module#ManagementPageModule' },
  { path: 'statics/surface-report', loadChildren: './statics/surface-report/surface-report.module#SurfaceReportPageModule' },
  { path: 'statics/surface-available', loadChildren: './statics/surface-available/surface-available.module#SurfaceAvailablePageModule' },
  { path: 'statics/project-set-budget', loadChildren: './statics/project-set-budget/project-set-budget.module#ProjectSetBudgetPageModule' },
  { path: 'drafts', loadChildren: './pages/boat/drafts/drafts.module#DraftsPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
