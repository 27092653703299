import { Component, Input, Output, EventEmitter, ElementRef, Renderer2, ViewEncapsulation, ViewChild, OnChanges, PLATFORM_ID, Inject, SecurityContext } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { TaskService, TaskModel, TaskHistoryModel } from 'src/app/core/services/tasks.service';
import { take } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { N7TaskModalComponent } from '../n7-task-modal/n7-task-modal.component'

import * as _ from 'lodash';
import * as moment from 'moment';

const defaultItem = {};

@Component({
  selector: 'n7-task-list',
  templateUrl: './n7-task-list.component.html',
  styleUrls: [
    './n7-task-list.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class N7TaskListComponent implements OnChanges {
  public type: string = 'preview';
  public tasks: any[] = [];
  public checkedTasks: any[] = [];
  public isFiltered: boolean = false;
  public isLoading: boolean = false;
  public filteredSearchingText: string = 'Looking for your tasks...';
  public filteredNothingFoundText: string = 'Nothing found';
  public searchingText: string = '';
  public nothingFoundText: string = 'Nothing here yet';
  public nothingFoundDesc: string = 'Add a new point to start gathering data';

  public taskCircleIcons: any;

  constructor(
    public modalController: ModalController
  ) {
    this.taskCircleIcons = {
      draft: '/assets/pins/Draft/Circle.svg',
      submitted: '/assets/pins/Sent/Circle.svg',
      'closed-submitted': '/assets/pins/Sent/Circle.svg',
      accepted: '/assets/pins/Accepted/Circle.svg',
      'closed-accepted': '/assets/pins/Accepted/Circle.svg',
      'closed-denied': '/assets/pins/Closed/Declined/Circle.svg',
      denied: '/assets/pins/Declined/Circle.svg',
      'in progress': '/assets/pins/Progress/Circle.svg',
      'closed-in progress': '/assets/pins/Progress/Circle.svg',
      completed: '/assets/pins/Completed/Circle.svg',
      'closed-completed': '/assets/pins/Closed/Completed/Circle.svg',
      open: '/assets/pins/Remark/Open/Circle.svg',
      'closed-open': '/assets/pins/Remark/NoActions/Closed/Circle.svg',
      no_actions: '/assets/pins/Remark/NoActions/Circle.svg',
      'closed-no_actions': '/assets/pins/Remark/NoActions/Closed/Circle.svg',
      local_actions: '/assets/pins/Remark/Local/Circle.svg',
      'closed-local_actions': '/assets/pins/Remark/Local/Closed/Circle.svg',
      total_actions: '/assets/pins/Remark/Total/Circle.svg',
      'closed-total_actions': '/assets/pins/Remark/Total/Closed/Circle.svg',
      monitored: '/assets/pins/Monitored/Circle.svg',
      'closed-monitored': '/assets/pins/Monitored/Circle.svg',
    };
  }

  @Input() set itemType(val: string) {
    this.type = (val !== undefined && val !== null) ? val : 'preview';
  }

  @Input() set items(val: any[]) {
    this.tasks = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set checkedItems(val: any[]) {
    this.checkedTasks = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set loading(val: boolean) {
    this.isLoading = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set filtered(val: boolean) {
    this.isFiltered = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set filteredSearchingTitle(val: string) {
    this.filteredSearchingText = (val !== undefined && val !== null) ? val : 'Looking for your tasks...';
  }

  @Input() set filteredNothingFoundTitle(val: string) {
    this.filteredNothingFoundText = (val !== undefined && val !== null) ? val : 'Nothing found';
  }

  @Input() set searchingTitle(val: string) {
    this.searchingText = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set nothingFoundTitle(val: string) {
    this.nothingFoundText = (val !== undefined && val !== null) ? val : 'Nothing here yet';
  }

  @Input() set nothingFoundHint(val: string) {
    this.nothingFoundDesc = (val !== undefined && val !== null) ? val : 'Add a new point to start gathering data';
  }

  @Output() itemClick = new EventEmitter();

  ngOnChanges() { }

  onTaskClick($event) {
    this.itemClick.emit($event);
  }
}
